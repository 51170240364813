import theme from '@/style';

export const dateImg = require('@assets/icons/calendar.webp');
export const sanImg = require('@components/assets/icons/filter/san.webp');
export const filterImg = require('@assets/icons/filter-primary.webp');

export const filterColor = {
  sortRed: theme.basicColor.primary,
  sortGrey: '#9F9F9F',
};

export const down = require('@components/assets/icons/filter/down.webp');
export const up = require('@components/assets/icons/filter/up.webp');
